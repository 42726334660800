/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: "Janna";
    src: url("./assets/fonts/Janna\ LT\ Bold.ttf") format("truetype");
    /* Safari, Android, iOS */
}

body {
    font-family: "Janna", Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f0f0f0;
    direction: rtl;
    font-size: 18px;
    line-height: 2em;
}

.nav-link {
    text-decoration: none;
    color: #007bff;
    font-size: 18px;
    margin-top: 10px;
    display: inline-block;
  }
  
  .nav-link:hover {
    text-decoration: underline;
  }